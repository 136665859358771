<template>
    <div class="stores-page">
        <section class="page-kv p-0">
            <div class="kv-img ratio-kv">
                <!-- 桌機圖 -->
                <img :src="branchImage.branchBannerPc" :alt="meta.alt.alt_4_1" class="w-100 d-none d-md-inline-block" v-if="branchImage.branchBannerPc != ''">
                <!-- 手機圖 -->
                <img :src="branchImage.branchBannerMobile" :alt="meta.alt.alt_4_1" class="w-100 d-inline-block d-md-none" v-if="branchImage.branchBannerMobile != ''">
            </div>
            <div class="kv-heading">
                <h1>服務據點</h1>
            </div>
        </section>
        <section class="stores-intro">
            <h2 class="text-center" v-html="about.branchSummary"></h2>
        </section>
        <section class="stores-collection">
            <div class="container">
                <div class="stores-view-filter">
                    <div class="stores-filter">
                        <div class="dropdown" v-if="mode == 1">
                            <button class="btn btn-select w-100 justify-content-start" type="button" id="dropdownMenu1" data-bs-toggle="dropdown" aria-expanded="false">
                                {{ checkedCity == '' ? '選取縣市' : checkedCity }}
                                <i class="icon">
                                    <IconSortDown class="down"/>
                                    <IconSortUp class="up"/>
                                </i>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                                <li v-for="(city, index) in cityList"><a class="dropdown-item" href="javascript:;" @click="changeCity(city)">{{ city.city }}</a></li>
                            </ul>
                        </div>
                        <div class="dropdown" v-if="mode == 1">
                            <button class="btn btn-select w-100 justify-content-start" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                {{ checkedTown == '' ? '選取區域' : checkedTown }}
                                <i class="icon">
                                    <IconSortDown class="down"/>
                                    <IconSortUp class="up"/>
                                </i>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                <li :value="index" v-for="(town, index) in townList"><a class="dropdown-item" href="javascript:;" @click="changeTown(town)">{{ town }}</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="view-switch">
                        <button @click="changeMode(1)" :class="mode == 1 ? 'btn btn-link active' : 'btn btn-link'">
                            <IconMap class="me-2"/>
                            <span class="text">地圖模式</span>
                        </button>
                        <button @click="changeMode(2)" :class="mode == 2 ? 'btn btn-link active' : 'btn btn-link'">
                            <IconGrid class="me-2"/>
                            <span class="text">列表模式</span>
                        </button>
                    </div>
                </div>
                <div class="map-view row g-0 g-lg-3" v-if="mode == 1">
                    <div class="col-lg-8 col-md-7">
                        <div class="map-content" v-if="mapShow == 1">
                            <l-map
                                ref="myMap"
                                :zoom="zoom"
                                :center="center"
                                :options="options"
                                style="height: 450px;">
                                <!-- 載入圖資 -->
                                <l-tile-layer :url="url" :attribution="attribution"/>
                                <!-- 自己所在位置 -->
                                <l-marker ref="location" :lat-lng="houseLatLng">
                                    <l-icon icon-url="/map_icon/pin.png" :icon-size="icon.iconSize"/>
                                    <l-popup>
                                        你的位置
                                    </l-popup>
                                </l-marker>
                                <!-- 創建標記點 -->
                                <l-marker :lat-lng="item.latLng" v-for="(item, index) in markerList" @click="markerClick(index)" @add="markerAdd($event, index)">
                                    <!-- 標記點樣式判斷 -->
                                    <l-icon icon-url="/map_icon/pin.png" :icon-size="icon.iconSize"/>
                                    <!-- 彈出視窗 -->
                                    <l-popup>{{ item.name }}</l-popup>
                                </l-marker>
                            </l-map>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-5">
                        <div class="map-list-group store-list">
                            <template v-for="(item, index) in branchList">
                                <div :class="'list-content' + (index === checkedBranch ? ' active' : '')" v-if="item.hide != 1" @click="goto(item, index)">
                                    <h3 class="title mb-2">{{ item.title }}</h3>
                                    <div class="info">
                                        <p><i class="icon-phone">
                                            <IconPhoneSolid class="me-2"/>
                                        </i><a :href="'tel:' + item.phone">{{ item.phone }}</a></p>
                                        <p><i class="icon-pin">
                                            <IconPinSolid class="me-2"/>
                                        </i><a href="javascript:;" class="ml-2 link-underline">{{ item.address }}</a></p>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="block-view">
                    <div class="row g-3 gy-md-5 gx-md-4" v-if="mode == 2">
                        <div class="col-12 col-sm-4 col-lg-3" v-for="(item, index) in branchList">
                            <LocalGrid :title="item.title" :phone="item.phone" :address="item.address" :photo="item.img"></LocalGrid>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import LocalGrid from '@/components/LocalGrid.vue'
import IconMap from '@/components/icons/IconMap.vue'
import IconGrid from '@/components/icons/IconGrid.vue'
import IconSortUp from '@/components/icons/IconSortUp.vue'
import IconSortDown from '@/components/icons/IconSortDown.vue'
import IconPhoneSolid from '@/components/icons/IconPhone_solid.vue'
import IconPinSolid from '@/components/icons/IconPin_solid.vue'

import "leaflet/dist/leaflet.css"
import {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LPopup
} from "@vue-leaflet/vue-leaflet"

export default {
    name: 'Stores',
    components: {
        IconMap,
        IconGrid,
        LocalGrid,
        IconSortUp,
        IconSortDown,
        LMap,
        LIcon,
        LTileLayer,
        LMarker,
        LPopup,
        IconPhoneSolid,
        IconPinSolid
    },
    data() {
        return {
            branchImage: {
                branchBannerPc: '',
                branchBannerMobile: '',
                branchImagePc: '',
                branchImageMobile: '',
            },
            branchList: [],
            checkedBranch: '',
            cityList: [
                {
                    city: '',
                    town: []
                }
            ],
            townList: [],
            checkedCity: '選取縣市',
            checkedTown: '選取區域',
            mode: 1,
            about: {},
            meta: {},

            mapShow: 0,
            map: {},
            marker: {},
            markerList: [],
            infoWindowList: [],

            zoom: 16,
            // center: [22.612961, 120.304167],
            // houseLatLng: [22.612961, 120.304167],
            center: [24.7920261, 120.923536],
            houseLatLng: [24.7920261, 120.923536],
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            attribution: `© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors`,
            options: {
                scrollWheelZoom: false,
            },
            icon: {
                type: [
                    "/icon/icon-1.png",
                    "/icon/icon-2.png",
                    "/icon/icon-3.png",
                    "/icon/icon-4.png",
                    "/icon/icon-5.png",
                ],
                shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
                iconSize: [31, 38],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41]
            }
        }
    },
    methods: {
        track: function (type, typeName) {
            if (this.about.ga != '') {
                ga('send', 'event', type, typeName);
            }
            if (this.about.pixel1 != '') {
                fbq('trackSingleCustom', this.about.pixel1, type, {houseType: typeName});
            }
            if (this.about.pixel2 != '') {
                fbq('trackSingleCustom', this.about.pixel2, type, {houseType: typeName});
            }
        },
        changeMode: function (mode) {
            this.mode = mode;
        },
        changeCity: function (city) {
            if (city.city == this.checkedCity) {
                return false;
            }
            this.checkedCity = city.city;
            this.checkedTown = '';
            this.townList = city.town;
            for (let x = 0; x < this.branchList.length; x++) {
                this.branchList[x].hide = this.branchList[x].address.indexOf(this.checkedCity) != -1 ? 0 : 1;
            }
        },
        changeTown: function (town) {
            this.checkedTown = town;
            for (let x = 0; x < this.branchList.length; x++) {
                if (this.branchList[x].address.indexOf(this.checkedCity) == -1) {
                    continue;
                }
                this.branchList[x].hide = this.branchList[x].address.indexOf(this.checkedTown) != -1 ? 0 : 1;
            }
        },
        initMap: function () {
            for (let x = 0; x < this.branchList.length; x++) {
                let item = this.branchList[x];
                if (x == 0) {
                    this.center = [item.lat, item.lng];
                    this.houseLatLng = [item.lat, item.lng];
                }
                let marker = {
                    // type: item.type,
                    name: item.title,
                    latLng: [item.lat, item.lng],
                };
                this.markerList.push(marker)
            }
            this.mapShow = 1;
        },
        markerClick: function (index) {
            this.checkedBranch = index;
        },
        markerAdd: function (event, index) {
            this.markerList[index].event = event;
        },
        goto: function (branch, index) {
            this.checkedBranch = index;
            //重新定位地圖位置與標記點位置
            this.center = [branch.lat, branch.lng];
            for (let x = 0; x < this.markerList.length; x++) {
                if (branch.title == this.markerList[x].name) {
                    this.markerList[x].event.target.openPopup()
                    break;
                }
            }
        },
    },
    mounted() {
        this.axios.get('/api/agents/branch')
            .then(response => (
                this.branchImage = response.data.branchImage,
                    this.branchList = response.data.branchList,
                    this.cityList = response.data.cityList,
                    this.about = response.data.about,
                    this.meta = response.data.meta,
                    this.initMap(),
                    document.title = this.meta.title,
                    $("meta[property='og:title']").attr("content", this.meta.title),
                    document.getElementsByTagName('meta')["description"].content = this.meta.description,
                    $("meta[property='og:description']").attr("content", this.meta.description)
            ))
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    },
}
</script>
